import { UbiverseSequenceData } from '../types';

export const delays = [ 8, 9, 10, 11, 12, 14, 16, 22, 30, 40 ];

export function convertPayloadToTrigger(payload: UbiverseSequenceData, majorCounts: number, bpm: number) {
  console.log(payload, 'payload')
  let _intensity = Math.floor(payload.intensity);
  if (_intensity < 1) _intensity = 1;
  if (_intensity > 10) _intensity = 10;

  const delay = delays[_intensity - 1];

  const majorSamples = ((60 / bpm) * majorCounts * 1000) / 5
  
  const timestampBySample = majorSamples * (payload.position / majorCounts) + delays[delays.length - 1];
  return [timestampBySample - delay, timestampBySample]
  // const timestamp = Math.round(payload.timestamp);
  // const offsetTimestamp = timestamp + delays[delays.length - 1]; // +40

  // return [offsetTimestamp - delay, offsetTimestamp];
}

export function convertSequenceToData(sequence: UbiverseSequenceData[] | string, offsetTime: number = 0, majorCounts: number = 4, bpm: number = 120) {
  let data: number[] = [];
  const _sequence = typeof(sequence) === 'string' ? JSON.parse(sequence) as UbiverseSequenceData[] : sequence;
  _sequence.forEach((t: UbiverseSequenceData) => {
    data = [...data, ...convertPayloadToTrigger({ intensity: t.intensity, timestamp: t.timestamp + offsetTime, position: t.position }, majorCounts, bpm)];
  });
  return data;
}