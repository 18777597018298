///////////////////////////////
// Ubiverse Connection Types //
///////////////////////////////
// Ubiverse Node Types
export const UbiverseNodeType__Parent = '__nodeType.parent' as const;
export const UbiverseNodeType__Child = '__nodeType.child' as const;
export type UbiverseNodeType = typeof UbiverseNodeType__Parent | typeof UbiverseNodeType__Child;

export type UbiverseClient = {
  id: string;
  type: UbiverseNodeType;
  data?: {
    label?: string;
    intensity?: number;
  }
}

///////////////////////////////

///////////////////////////////
// Ubiverse Event Types //
///////////////////////////////

// Ubiverse Event Topics
export const UbiverseEventTopic__Play = 'event.play' as const;
export const UbiverseEventTopic__Pause = 'event.pause' as const;
export const UbiverseEventTopic__SetData = 'event.set.data' as const;
export const UbiverseEventTopic__SetTime = 'event.set.time' as const;
export const UbiverseEventTopic__SetMute = 'event.set.mute' as const;
export const UbiverseEventTopic__ClearData = 'event.clear.data' as const;
export const UbiverseEventTopic__ClearTime = 'event.clear.time' as const;
export const UbiverseEventTopic__ClearMute = 'event.clear.mute' as const;
export const UbiverseEventTopic__ClearAll = 'event.clear.all' as const;
export const UbiverseEventTopic__Trigger = 'event.trigger' as const;
export const UbiverseEventTopic__Increment = 'event.increment' as const;
export const UbiverseEventTopic__SimStateChanged = 'event.sim.changed' as const;
export const UbiverseEventTopic__SessionListChanged = 'event.sessionList.changed' as const;
export const UbiverseEventTopicList = [
  UbiverseEventTopic__Play,
  UbiverseEventTopic__Pause,
  UbiverseEventTopic__SetData,
  UbiverseEventTopic__SetTime,
  UbiverseEventTopic__SetMute,
  UbiverseEventTopic__ClearData,
  UbiverseEventTopic__ClearTime,
  UbiverseEventTopic__ClearMute,
  UbiverseEventTopic__ClearAll,
  UbiverseEventTopic__Trigger,
  UbiverseEventTopic__Increment,
  UbiverseEventTopic__SimStateChanged,
  UbiverseEventTopic__SessionListChanged
] as const;
export type UbiverseEventTopic = typeof UbiverseEventTopicList[number];

// Ubiverse Event Topics
export type UbiverseEvent = {
  topic: UbiverseEventTopic;
  label: string;
  payloadType?: any; // Update
}

///////////////////////////////

///////////////////////////////
// Ubiverse Note Data Types //
///////////////////////////////
export type UbiverseSequenceData = {
  intensity: number;
  timestamp: number;
  position: number;
}

export type UbiverseNote = {
  nodeId: string;
  intensity: number;
  timestamp: number;
  position: number;
}